
      @keyframes loading-text-opacity {
        0% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @-moz-keyframes loading-text-opacity {
        0% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @-webkit-keyframes loading-text-opacity {
        0% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      @-o-keyframes loading-text-opacity {
        0% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes fadeInOut {
        0% {
          opacity: 0; /* Start with full transparency (invisible) */
        }
         25% {
          opacity: 1;
        }
        50% {
          opacity: 0; /* Gradually become fully visible */
        }
        100% {
          opacity: 1; /* Gradually become fully transparent again */
        }
      }

      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
      .inner-container{
        display: flex;
        flex-direction: row;
        width:"95%";
      }

      .ease {
        animation: fadeInOut 5s ease-in-out infinite;
      }

      #loading-text {
        -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
        -o-animation: loading-text-opacity 2s linear 0s infinite normal;
        -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
        animation: loading-text-opacity 2s linear 0s infinite normal;
        opacity: 0;
      }
  